var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "q-form",
              { ref: "editForm" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "LBLBASEINFO" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.data,
                                    mappingType: _vm.mappingType,
                                    label: "LBLSAVE",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveInfo,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "배출구명",
                              name: "envAirMstOutletNm",
                            },
                            model: {
                              value: _vm.data.envAirMstOutletNm,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "envAirMstOutletNm", $$v)
                              },
                              expression: "data.envAirMstOutletNm",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "허가증상 일련번호",
                              name: "envAirMstOutletSems",
                            },
                            model: {
                              value: _vm.data.envAirMstOutletSems,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "envAirMstOutletSems", $$v)
                              },
                              expression: "data.envAirMstOutletSems",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "배출구 일련번호",
                              name: "envAirMstOutletName",
                            },
                            model: {
                              value: _vm.data.envAirMstOutletName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "envAirMstOutletName", $$v)
                              },
                              expression: "data.envAirMstOutletName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              type: "edit",
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.data.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "plantCd", $$v)
                              },
                              expression: "data.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "배출구종수",
                              name: "outletWidth",
                            },
                            model: {
                              value: _vm.data.outletWidth,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "outletWidth", $$v)
                              },
                              expression: "data.outletWidth",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "법정측정횟수",
                              name: "outletHeight",
                            },
                            model: {
                              value: _vm.data.outletHeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "outletHeight", $$v)
                              },
                              expression: "data.outletHeight",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-dept", {
                            attrs: {
                              type: "edit",
                              editable: _vm.editable,
                              label: "관리부서",
                              name: "deptCd",
                            },
                            model: {
                              value: _vm.data.deptCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "deptCd", $$v)
                              },
                              expression: "data.deptCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              editable: _vm.editable,
                              isFlag: true,
                              label: "LBLUSEFLAG",
                              name: "useFlag",
                            },
                            model: {
                              value: _vm.data.useFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "useFlag", $$v)
                              },
                              expression: "data.useFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              editable: _vm.editable,
                              isFlag: true,
                              label: "방지시설면제여부",
                              name: "preventExemptionFlag",
                            },
                            on: { input: _vm.changePrevent },
                            model: {
                              value: _vm.data.preventExemptionFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "preventExemptionFlag", $$v)
                              },
                              expression: "data.preventExemptionFlag",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.popupParam.envAirMstOutletId,
                expression: "popupParam.envAirMstOutletId",
              },
            ],
            staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5",
          },
          [
            _c(
              "c-table",
              {
                ref: "grid",
                attrs: {
                  title: "자가측정 검사항목",
                  tableId: "grid",
                  columns: _vm.grid.columns,
                  data: _vm.grid.data,
                  usePaging: false,
                  hideBottom: true,
                  columnSetting: false,
                  isFullScreen: false,
                  filtering: false,
                  isExcelDown: false,
                  gridHeight: "500px",
                  selection: "multiple",
                  rowKey: "envAirPlantInspectItemId",
                },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable && _vm.popupParam.envAirMstOutletId
                          ? _c("c-btn", {
                              attrs: { label: "LBLSAVE", icon: "save" },
                              on: { btnClicked: _vm.saverow1 },
                            })
                          : _vm._e(),
                        _vm.editable && _vm.popupParam.envAirMstOutletId
                          ? _c("c-btn", {
                              attrs: { label: "LBLADD", icon: "add" },
                              on: { btnClicked: _vm.addrow1 },
                            })
                          : _vm._e(),
                        _vm.editable && _vm.popupParam.envAirMstOutletId
                          ? _c("c-btn", {
                              attrs: { label: "LBLREMOVE", icon: "remove" },
                              on: { btnClicked: _vm.removeRow1 },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.popupParam.envAirMstOutletId,
                expression: "popupParam.envAirMstOutletId",
              },
            ],
            staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7",
          },
          [
            _c(
              "c-table",
              {
                ref: "grid2",
                attrs: {
                  title: "배출구별 방지시설/배출시설",
                  tableId: "grid2",
                  columns: _vm.grid2.columns,
                  data: _vm.grid2.data,
                  merge: _vm.grid2.merge,
                  usePaging: false,
                  hideBottom: true,
                  columnSetting: false,
                  isFullScreen: false,
                  filtering: false,
                  isExcelDown: false,
                  gridHeight: "500px",
                  selection: "multiple",
                  editable: _vm.editable,
                  rowKey: "envAirMstPreventiveId",
                },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable && _vm.popupParam.envAirMstOutletId
                          ? _c("c-btn", {
                              attrs: { label: "방지시설추가", icon: "add" },
                              on: { btnClicked: _vm.addrow2 },
                            })
                          : _vm._e(),
                        _vm.editable && _vm.popupParam.envAirMstOutletId
                          ? _c("c-btn", {
                              attrs: { label: "방지시설삭제", icon: "remove" },
                              on: { btnClicked: _vm.removeRow2 },
                            })
                          : _vm._e(),
                        _vm.editable && _vm.popupParam.envAirMstOutletId
                          ? _c("c-btn", {
                              attrs: { label: "차수저장", icon: "save" },
                              on: { btnClicked: _vm.saveRow2 },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }